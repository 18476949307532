/**
 * TopMenu
 */

import React from 'react';
import classNames from 'classnames';
import { useStyle } from 'Themes/theme';

import sD from './TopMenu.Dansskolanentre.module.scss';
import sK from './TopMenu.Kulturama.module.scss';
import sM from './TopMenu.Medborgarskolan.module.scss';
import sP from './TopMenu.Pahlmans.module.scss';
import sT from './TopMenu.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const TopMenu = ({ activeIds, items }) => {
    const s = useStyle(styles);

    return (
        <nav className={s['TopMenu']}>
            <ul className={s['TopMenu__List']}>
                {items.map((item, index) => (
                    <TopMenuItem
                        item={item}
                        activeIds={activeIds}
                        key={index}
                        index={index}
                    />
                ))}
            </ul>
        </nav>
    );
};

const TopMenuItem = ({item, activeIds, index}) => {
    const s = useStyle(styles);

    let classes = classNames(
        s['TopMenu__Item'],
        {[s[`TopMenu__Item--Current`]]: activeIds.includes(item.id) && index},
        {[s[`TopMenu__Item--CurrentPahlmans`]]: activeIds.includes(item.id)}
    );

    return (
        <li key={index} className={classes}>
            <a
                className={s['TopMenu__Link']}
                href={item.href}
            >
                {item.title}
            </a>
        </li>
    );
};

TopMenu.defaultProps = {
    activeIds: [],
    items: []
};

export default TopMenu;
