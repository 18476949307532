/**
 * TopMenuSelect
 */

import React from 'react';
import { useStyle } from 'Themes/theme';

import sD from './TopMenuSelect.Dansskolanentre.module.scss';
import sK from './TopMenuSelect.Kulturama.module.scss';
import sM from './TopMenuSelect.Medborgarskolan.module.scss';
import sP from './TopMenuSelect.Pahlmans.module.scss';
import sT from './TopMenuSelect.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const TopMenuSelect = ({ activeIds, items }) => {
    const s = useStyle(styles);

    const sortItemsByActiveId = (items, activeIds) => {
        let sortedList = [];
        items.map(item => {
            if (activeIds.includes(item.id)) {
                sortedList.unshift(item);
            } else {
                sortedList.push(item);
            }
        });

        return sortedList;
    };

    const selectChange = (event) => {
        window.location = event.target.value;
    };

    const sortedItems = sortItemsByActiveId(items, activeIds);

    return (
        <nav className={s['TopMenuSelect']}>
            <select onChange={selectChange} className={s['TopMenuSelect__List']}>
                {sortedItems.map((item, index) =>
                    <TopMenuItem
                        item={item}
                        key={index}
                        index={index}
                    />
                )}
            </select>
        </nav>
    );
};

const TopMenuItem = ({ item, index }) => {
    const s = useStyle(styles);

    return (
        <option key={index} className={s['TopMenuSelect__Item']} value={item.href}>
            {item.title}
        </option>
    );
};

TopMenuSelect.defaultProps = {
    activeIds: [],
    items: []
};

export default TopMenuSelect;
