/**
 * SearchInput
 */

import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useStyle } from 'Themes/theme';

import sM from './SearchInput.Medborgarskolan.module.scss';
import sP from './SearchInput.Pahlmans.module.scss';

const styles = {
    Medborgarskolan: sM,
    Pahlmans: sP,
};

const SearchInput = ({
    id,
    type,
    q,
    triggerFocus,
    changeInputHandler,
}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const inputRef = useRef();

    useEffect(() => {
        if(triggerFocus && inputRef && inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus();
            }, 100);
        }
    }, []);

    let input = {};
    if(changeInputHandler) {
        input = {
            onChange: changeInputHandler,
            value: q,
        };
    } else {
        input = {
            defaultValue: q,
            autoFocus: triggerFocus,
        };
    }

    const placeholder = t([`searchInput.placeholder.${type}`, 'searchInput.placeholder.default']);
    const submit = t([`searchInput.submit.${type}`, 'searchInput.submit.default']);

    return (
        <div className={s['SearchInput']}>
            <div className={s['SearchInput__Container']}>
                <div className={s['SearchInput__Field']}>
                    <label className="sr-only" htmlFor={id}>
                        {placeholder}
                    </label>
                    <input
                        {...input}
                        type="search"
                        name="q"
                        id={id}
                        className={s['SearchInput__Input']}
                        placeholder={placeholder}
                        autoComplete="off"
                        ref={inputRef}
                    />
                </div>
                <button
                    className={s['SearchInput__Submit']}
                    type="submit"
                ><span className={s['SearchInput__SubmitText']}>{submit}</span></button>
            </div>
        </div>
    );
};

SearchInput.defaultProps = {
    id: 'search_input_field',
    q: '',
    type: '',
    triggerFocus: false,
    changeInputHandler: null,
};

SearchInput.propTypes = {
    id: PropTypes.string,
    q: PropTypes.string,
    type: PropTypes.string,
    triggerFocus: PropTypes.bool,
    changeInputHandler: PropTypes.func,
};

export default SearchInput;
