/**
 * MenuMobile
 */

import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import ButtonMenu from 'Components/ButtonMenu';
import { AnimateUpDown } from 'Components/Animate';
import { useStyle } from 'Themes/theme';

import sD from './MenuMobile.Dansskolanentre.module.scss';
import sK from './MenuMobile.Kulturama.module.scss';
import sM from './MenuMobile.Medborgarskolan.module.scss';
import sP from './MenuMobile.Pahlmans.module.scss';
import sT from './MenuMobile.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const MenuMobile = ({
    primary = {},
    secondary = {},
    info = {},
    search = {},
    isMinimal = false,
}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const [active, setActive] = useState(false);

    useEffect(() => {
        document.body.classList.toggle('MenuMobileActive', active);
    }, [active]);

    const clickHandler = (item, e) => {
        if(item.onClick) {
            e.preventDefault();
            let fn = item.onClick;
            if(typeof fn === 'function') {
                fn();
            } else {
                fn = window[item.onClick];
                if(typeof fn === 'function') {
                    fn();
                }
            }
        }
    };

    const classes = classNames(
        s['MenuMobile'],
        {[s[`MenuMobile--Active`]]: active},
        {[s[`MenuMobile--Minimal`]]: isMinimal},
    );
    const buttonText = active ? t('header.menuMobile.close') : t('header.menuMobile.open');

    return (
        <div className={classes}>
            <div className={s['MenuMobile__Toggle']}>
                {!_.isEmpty(search) &&
                    <Search {...search} />
                }

                <ButtonMenu
                    active={active}
                    text={buttonText}
                    onClick={() => setActive(!active)}
                    modifier='Mobile'
                    isMinimal={isMinimal}
                />
            </div>

            <AnimateUpDown
                isVisible={active}
                className={s['MenuMobile__Animate']}
            >
                <div className={s['MenuMobile__Container']}>
                    <nav className={s['MenuMobile__Content']}>
                        <ul className={classNames(s['MenuMobile__List'], s['MenuMobile__List--Primary'])}>
                            {!_.isEmpty(primary) && primary.items.map((item, index) =>
                                <MenuItem
                                    key={index}
                                    type="Primary"
                                    clickHandler={(e) => clickHandler(item, e)}
                                    {...item}
                                />
                            )}
                        </ul>

                        {!_.isEmpty(secondary) &&
                            <ul className={classNames(s['MenuMobile__List'], s['MenuMobile__List--Secondary'])}>
                                {secondary.items.map((item, index) =>
                                    <MenuItem
                                        key={index}
                                        type="Secondary"
                                        clickHandler={(e) => clickHandler(item, e)}
                                        {...item}
                                    />
                                )}
                            </ul>
                        }
                    </nav>

                    {!_.isEmpty(info) &&
                        <MenuButton {...info} />
                    }
                </div>
            </AnimateUpDown>
        </div>
    );
};

const MenuItem = ({title, href, clickHandler, type, modifier, attr}) => {
    const [loading, setLoading] = useState(false);
    const linkRef = useRef();

    const s = useStyle(styles);

    const classes = classNames(
        s['MenuMobile__Link'],
        {[s[`MenuMobile__Link--${modifier}`]]: modifier},
        {[s[`MenuMobile__Link--${type}`]]: type},
        {[s['MenuMobile__Link--Loading']]: loading},
    );

    return (
        <li className={s['MenuMobile__Item']}>
            <a
                className={classes}
                href={href}
                onClick={(e) => clickHandler(e)}
                ref={linkRef}
                {...attr}
            >
                {title}
            </a>
        </li>
    );
};

const MenuButton = ({ text = '', href = '' }) => {
    const s = useStyle(styles);
    return (
        <a className={classNames(s['MenuMobile__ButtonLink'], s['MenuMobile__ButtonLink--Mobile'])} href={href}>
            {text}
        </a>
    );
};

const Search = ({
    title,
    href,
}) => {

    const s = useStyle(styles);

    return (
        <a
            className={s['MenuMobile__Search']}
            href={href}
        >
            <span className={s['MenuMobile__SearchIcon']} />
            <span className="sr-only">{title}</span>
        </a>
    );
};

export default MenuMobile;
