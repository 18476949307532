/**
 * Accordion
 */

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { AnimateUpDown } from 'Components/Animate';
import Table from './../Table';
import { useStyle } from 'Themes/theme';

import sD from './Accordion.Dansskolanentre.module.scss';
import sK from './Accordion.Kulturama.module.scss';
import sM from './Accordion.Medborgarskolan.module.scss';
import sP from './Accordion.Pahlmans.module.scss';
import sT from './Accordion.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Accordion = ({title, isLocked, slug, text, items, selected}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const [open, setOpen] = useState(false);
    const [active, setActive] = useState(selected.includes(slug));

    useEffect(() => {
        const isActive = selected.includes(slug);
        if(isActive !== active) {
            setActive(isActive);
        }
    }, [selected]);

    const id = `cookie-category-${slug}`;

    const classes = classNames(
        s['CookiePopupAccordion'],
        {[s['CookiePopupAccordion--Open']]: open},
        {[s['CookiePopupAccordion--Active']]: active},
    );

    const selectText = active ? 'deselectAccordion' : 'selectAccordion';

    return (
        <div className={classes}>
            <div className={s['CookiePopupAccordion__Header']}>
                <h3 className={s['CookiePopupAccordion__Title']}>{title}</h3>

                {!isLocked &&
                    <div className={s['CookiePopupAccordion__Selection']}>
                        <input
                            type="checkbox"
                            name="category"
                            value={slug}
                            id={id}
                            onChange={() => setActive(!active)}
                            checked={active === true}
                        />
                        <label htmlFor={id}>
                            <span className="sr-only">
                                {t(`cookiePopup.${selectText}`, {title: title})}
                            </span>
                        </label>
                    </div>
                }

                <button
                    className={s['CookiePopupAccordion__Toggle']}
                    type="button"
                    onClick={() => setOpen(!open)}
                >
                    <span className="sr-only">
                        {t('cookiePopup.openAccordion', {title: title})}
                    </span>
                </button>
            </div>

            <AnimateUpDown
                isVisible={open}
            >
                <div className={s['CookiePopupAccordion__Content']}>
                    {!_.isEmpty(text) &&
                        <p className={s['CookiePopupAccordion__Text']}>{text}</p>
                    }

                    <div className={s['CookiePopupAccordion__Table']}>
                        <Table items={items} />
                    </div>
                </div>
            </AnimateUpDown>
        </div>
    );
};

Accordion.propTypes = {
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    text: PropTypes.string,
    items: PropTypes.array.isRequired,
    selected: PropTypes.array,
};

Accordion.defaultProps = {
    title: '',
    slug: '',
    text: '',
    items: [],
    selected: [],
};

export default Accordion;
