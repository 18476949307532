/**
 * Header
 */
import React, { Fragment, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import BaseContext from 'Layouts/BasePage/BaseContext';
import { AnimateInOut } from 'Components/Animate';
import Menu from './Menu';
import TopMenu from './TopMenu';
import TopMenuSelect from './TopMenuSelect';
import MenuMobile from './MenuMobile';
import Search from './Search';
import { useStyle } from 'Themes/theme';

import LogoMedborgarskolan from 'public/img/logo-medborgarskolan.svg';
import LogoPahlmans from 'public/img/logo-pahlmans.webp';
import LogoPahlmansGy from 'public/img/logo-pahlmans-gy.webp';
import LogoKulturama from 'public/img/logo-kulturama.svg';
import LogoTillskararakademin from 'public/img/logo-tillskararakademin.svg';
import LogoDansskolanentre from 'public/img/logo-dansskolan-entre.svg';

import sD from './Header.Dansskolanentre.module.scss';
import sK from './Header.Kulturama.module.scss';
import sM from './Header.Medborgarskolan.module.scss';
import sP from './Header.Pahlmans.module.scss';
import sT from './Header.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Header = ({
    title,
    topMenu,
    menu,
    info,
    search,
    backLink,
    isApplication,
    isMinimal,
}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const [showSearch, setShowSearch] = useState(false);

    const {
        primary,
        secondary,
        activeIds,
    } = menu;

    const regSearchItem = _.isEmpty(search) ? null : {
        title: t('header.search'),
        href: search.action,
        modifier: 'Search',
        showSearch: showSearch,
        onClick: () => setShowSearch(!showSearch),
    };
    const mobileSearchItem = _.isEmpty(search) ? null : {
        title: t('header.search'),
        href: search.action,
        modifier: 'Search',
    };

    const mobileMenu = Object.assign({}, menu, {
        secondary: {
            ...secondary,
            items: [].concat(_.get(secondary, 'items', []), []),
        },
    });
    const serviceMenu = Object.assign({}, secondary, {
        items: [
            ...(regSearchItem ? [regSearchItem] : []),
        ].concat(_.get(secondary, 'items', []), []),
    });

    const classes = classNames(
        s['Header'],
        {[s['Header--Minimal']]: isMinimal},
        {[s['Header--Application']]: isApplication},
    );

    return (
        <header className={classes}>
            <div className={s['Header__Container']}>
                {isApplication &&
                    <div className={s['Header__Content']}>
                        <a href={backLink} className={s['Header__Back']}>
                            {t('header.back')}
                        </a>

                        <Link href="/" className={s['Header__Logo']}>
                            <span className="sr-only">{t('header.toStart')}</span>
                            <Logo />
                        </Link>
                    </div>
                }
                {!isApplication &&
                    <Fragment>
                        <div className={s['Header__Menus']}>
                            <div className={s['Header__Top']}>
                                {!_.isEmpty(topMenu) &&
                                    <TopMenu {...topMenu} />
                                }
                            </div>
                            <div className={s['Header__Service']}>
                                {!_.isEmpty(serviceMenu) &&
                                    <Menu
                                        {...serviceMenu}
                                        activeIds={activeIds}
                                        modifier="Secondary"
                                        placement="Top"
                                    />
                                }
                            </div>
                            <div className={s['Header__Main']}>
                                {!_.isEmpty(topMenu) &&
                                    <TopMenuSelect {...topMenu} />
                                }

                                <Link href="/" className={s['Header__Logo']}>
                                    <span className="sr-only">{title}</span>
                                    <Logo />
                                </Link>

                                <Menu
                                    {...primary}
                                    activeIds={activeIds}
                                    modifier="Primary"
                                    isMinimal={isMinimal}
                                    placement="Main"
                                />

                                {!_.isEmpty(serviceMenu) &&
                                    <Menu
                                        {...serviceMenu}
                                        activeIds={activeIds}
                                        modifier="Secondary"
                                        isMinimal={isMinimal}
                                        placement="Main"
                                    />
                                }

                                {!_.isEmpty(info) &&
                                    <MenuButton {...info} />
                                }
                            </div>
                        </div>

                        <MenuMobile
                            {...mobileMenu}
                            info={info}
                            search={mobileSearchItem}
                            isMinimal={isMinimal}
                        />

                        {!_.isEmpty(search) &&
                            <div className={s['Header__Search']}>
                                <Search
                                    {...search}
                                    open={showSearch}
                                />
                            </div>
                        }
                    </Fragment>
                }
            </div>

            {!_.isEmpty(search) &&
                <AnimateInOut
                    isVisible={showSearch}
                >
                    <div
                        className={s['Header__Backdrop']}
                        onClick={() => setShowSearch(false)}
                    />
                </AnimateInOut>
            }
        </header>
    );
};

Header.propTypes = {
    title: PropTypes.string,
    menu: PropTypes.object,
    topMenu: PropTypes.object,
    search: PropTypes.object,
    info: PropTypes.object,
    backLink: PropTypes.string,
    isApplication: PropTypes.bool,
    isMinimal: PropTypes.bool,
};


Header.defaultProps = {
    title: '',
    menu: {
        labels: {},
        activeIds: [],
        primary: {
            items:[]
        },
        secondary: {
            items:[]
        },
    },
    topMenu: {},
    search: {},
    info: {},
    backLink: '',
    isApplication: false,
    isMinimal: false,
};

const Logo = () => {
    const {currentTheme} = useContext(BaseContext);
    const {t} = useTranslation();
    if(_.isEmpty(currentTheme)) {
        return null;
    }

    const logos = {
        Medborgarskolan: LogoMedborgarskolan,
        Pahlmans: LogoPahlmans,
        PahlmansGy: LogoPahlmansGy,
        Kulturama: LogoKulturama,
        Tillskararakademin: LogoTillskararakademin,
        Dansskolanentre: LogoDansskolanentre,
    };
    const logo = logos[currentTheme];
    const {src, width, height} = logo;

    return (
        <img
            src={src}
            alt={t('site.name')}
            width={width}
            height={height}
            loading="lazy"
        />
    );
};

const MenuButton = ({ text = '', href = '' }) => {
    const s = useStyle(styles);

    return (
        <a className={s['Header__ButtonLink']} href={href}>{text}</a>
    );
};

export default Header;
