/**
 * Search
 */

import React from 'react';
import { AnimateUpDown } from 'Components/Animate';
import SearchInput from 'Components/SearchInput';
import { useStyle } from 'Themes/theme';

import sM from './Search.Medborgarskolan.module.scss';
import sP from './Search.Pahlmans.module.scss';

const styles = {
    Medborgarskolan: sM,
    Pahlmans: sP,
};

const Search = ({ open, action, ...props }) => {
    const s = useStyle(styles);

    return (
        <AnimateUpDown isVisible={open}>
            <div className={s['HeaderSearch']}>
                <form className={s['HeaderSearch__Container']} action={action}>
                    <SearchInput
                        id="header_search_input"
                        triggerFocus={true}
                        type="global"
                        {...props}
                    />
                </form>
            </div>
        </AnimateUpDown>
    );
};

Search.defaultProps = {
    open: false,
    action: '',
};

export default Search;
