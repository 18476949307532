import fetch from 'isomorphic-fetch';

const parseJSON = response => response.json();

const defaultHeaders = {
    Accept: 'application/json',
    'Accept-Language': 'sv,sv-SE;q=0.9',
    'Content-Type': 'application/json',
};

const buildHeaders = (headers) => {
    return {
        ...defaultHeaders,
        ...headers,
    };
};

const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
};


const httpGet = (url, lang) => {
    let headers = {};
    if(lang === 'sv') {
        headers['Accept-Language'] = 'sv,sv-SE;q=0.9';
    }
    return fetch(url, {
        headers: buildHeaders(headers),
        credentials: 'same-origin',
    })
        .then(checkStatus)
        .then(parseJSON)
        .catch((error) => console.error('Request error', error))
};

const httpPost = (url, data, headers) => (
    fetch(url, {
        method: 'post',
        headers: buildHeaders(headers),
        body: JSON.stringify(data),
        credentials: 'same-origin',
    })
        .then(checkStatus)
        .then(parseJSON)
        .catch((error) => console.error('Request error', error))
);

export {
    httpGet,
    httpPost,
};

