import React from 'react';
import PropTypes from 'prop-types';
import s from './WagtailUserbar.module.scss';

const WagtailUserbar = ({ html }) => {
    return (
        <div
            className={s['Root']}
            suppressHydrationWarning={true}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};

WagtailUserbar.propTypes = {
    html: PropTypes.string.isRequired,
};

export default WagtailUserbar;
