/**
 * EventSidebar
 */

import React from 'react';
import classNames from 'classnames';

import LogoMedborgarskolan from '../../public/inline-svg/logo-medborgarskolan.svg';
import { useStyle } from 'Themes/theme';

import sD from './EventSidebar.Dansskolanentre.module.scss';
import sK from './EventSidebar.Kulturama.module.scss';
import sP from './EventSidebar.Pahlmans.module.scss';
import sT from './EventSidebar.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const EventSidebar = ({ title, srText, modifier }) => {
    const s = useStyle(styles);

    const classes = classNames(
        s['EventSidebar'],
        {[s[`EventSidebar--${modifier}`]]: modifier }
    );

    return (
        <div className={classes}>
            <h3 className={s['EventSidebar__Title']}>
                {title}
                <span className='sr-only'> {srText}</span>
            </h3>
            <LogoMedborgarskolan />
        </div>
    );
};

EventSidebar.defaultProps = {
    title: '',
    srText: '',
    modifier: ''
};

export default EventSidebar;
