import _ from 'lodash';

// For forcing invalidation of current cookies
const cookiePopupName = 'cookie-popup';
const cookieVersion = '1.0';

const setCookie = (name, value, expirationDays = 30, domain = false, path = '/') => {
    let d = new Date();
    d.setTime(d.getTime() + (expirationDays*24*60*60*1000));
    const expires = d.toUTCString();
    let cookie = `${name}=${value};expires=${expires};path=${path}`;
    if(domain) {
        cookie += `;domain=${domain}`;
    }
    document.cookie = cookie;
};

const getCookie = (name) => {
    if (typeof document === 'undefined') {
        return '';
    }
    name = name + '=';
    const cookie = document.cookie.split(';');
    for(let i = 0; i < cookie.length; i++) {
        let part = cookie[i];
        while(part.charAt(0) === ' ') {
            part = part.substring(1);
        }
        if(part.indexOf(name) === 0) {
            return part.substring(name.length, part.length);
        }
    }
    return '';
};

const removeCookie = (cookieName) => {
    const cookie = getCookie(cookieName);
    if(!_.isEmpty(cookie)) {
        // console.log('removed cookie', cookieName);
        setCookie(cookieName, '', -1);
    }
};

/*
 * Use this function to check if the cookie is allowed to be saved for user
 * Pass in the cookie name and make sure it is added to list of cookies and
 * corresponding required category.
 * Make sure the cookie and category exist in admin settings.
 */
const isCookieAllowed = (cookiePopup, cookieName) => {
    const cookie = getCookie(cookiePopupName);
    if(_.isEmpty(cookie)) {
        // console.log('cookie popup not accepted');
        removeCookie(cookieName);
        return false;
    } else {
        const find = _.find(cookiePopup.items, { items: [ { name: cookieName } ]});
        const findIndex = _.findIndex(cookiePopup.items, { items: [ { name: cookieName } ]});
        const categorySlug = _.get(find, 'slug', '');
        const isRequiredCategory = categorySlug === 'required' || findIndex === 0;
        const status = _.get(JSON.parse(cookie), 'status', '');
        const categories = _.get(JSON.parse(cookie), 'categories', []);
        const version = _.get(JSON.parse(cookie), 'version', '');
        // if(status !== 'accept') {
        //     console.log('cookie not accepted', cookieName);
        // } else if(find === undefined) {
        //     console.log('cookie is accepted but doesn\'t exist in settings', cookieName);
        // } else if(isRequiredCategory) {
        //     console.log('cookie is accepted and is in required category', cookieName);
        // } else {
        //     console.log('is cookie allowed', cookieName, categories, categorySlug, categories.includes(categorySlug));
        // }
        const isAllowed = version === cookieVersion && status === 'accept' && (
            find === undefined || isRequiredCategory || categories.includes(categorySlug)
        );
        if(!isAllowed) {
            removeCookie(cookieName);
        }
        return isAllowed;
    }
};

export {
    cookiePopupName,
    cookieVersion,
    setCookie,
    getCookie,
    removeCookie,
    isCookieAllowed,
};
