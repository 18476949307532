/**
 * Follow
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import { useStyle } from 'Themes/theme';

import sD from './Follow.Dansskolanentre.module.scss';
import sK from './Follow.Kulturama.module.scss';
import sM from './Follow.Medborgarskolan.module.scss';
import sP from './Follow.Pahlmans.module.scss';
import sT from './Follow.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Follow = ({ title, wysiwyg, isMinimal, items }) => {
    const {t, i18n} = useTranslation();

    const s = useStyle(styles);

    if (_.isEmpty(wysiwyg) && _.isEmpty(items)) {
        return null;
    }

    const classes = classNames(
        s['Follow'],
        {[s['Follow--Minimal']]: isMinimal },
    );

    return (
        <div className={classes}>
            {!_.isEmpty(title) &&
                <h2 className={s['Follow__Title']}>{title}</h2>
            }
            {!_.isEmpty(wysiwyg) &&
                <div className={s['Follow__Wysiwyg']} dangerouslySetInnerHTML={{ __html: wysiwyg }} />
            }
            {!_.isEmpty(items) && (
                <nav className={s['Follow__Items']} aria-label={t('follow.label')}>
                    {items.map(({type, url}, index) => {
                        const name = _.upperFirst(type);
                        const iconClasses = classNames(
                            s['Follow__Icon'],
                            [s[`Follow__Icon--${name}`]],
                        );
                        return (
                            <a key={index} href={url} className={iconClasses}>
                                <span className="sr-only">
                                    {i18n.exists(`follow.type.${type}`) ? t(`follow.type.${type}`) : name}
                                </span>
                            </a>
                        );
                    })}
                </nav>
            )}
        </div>
    );
};

Follow.propTypes = {
    wysiwyg: PropTypes.string,
    isMinimal: PropTypes.bool,
    followNew: PropTypes.bool,
};

Follow.defaultProps = {
    wysiwyg: '',
    isMinimal: false,
    followNew: false,
};

export default Follow;
