/**
 * ButtonMenu
 */

import React from 'react';
import classNames from 'classnames';
import SanitizeHTML from 'sanitize-html-react';
import _ from 'lodash';
import { useStyle } from 'Themes/theme';

import sD from './ButtonMenu.Dansskolanentre.module.scss';
import sK from './ButtonMenu.Kulturama.module.scss';
import sM from './ButtonMenu.Medborgarskolan.module.scss';
import sP from './ButtonMenu.Pahlmans.module.scss';
import sT from './ButtonMenu.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const ButtonMenu = ({
    active = false,
    text = 'Text',
    onClick = null,
    modifier = '',
    topItemTitle = '',
    isMinimal = false,
}) => {
    const buttonText = SanitizeHTML(text);

    const s = useStyle(styles);

    const classes = classNames(
        s['ButtonMenu'],
        {[s['ButtonMenu--Active']]: active},
        {[s['ButtonMenu--Minimal']]: isMinimal},
        {[s[`ButtonMenu--${modifier}`]]: modifier},
    );

    return (
        <button
            type="button"
            onClick={onClick}
            className={classes}
            aria-label={buttonText}
        >
            <div className={s['ButtonMenu__Container']}>
                <span className={s['ButtonMenu__Icon']}>
                    <span className={s['ButtonMenu__IconLine']}></span>
                    <span className={s['ButtonMenu__IconLine']}></span>
                    <span className={s['ButtonMenu__IconLine']}></span>
                </span>
                <span
                    className={s['ButtonMenu__Text']}
                    dangerouslySetInnerHTML={{__html: buttonText}}
                />
                {!_.isEmpty(topItemTitle) &&
                    <span
                        className={s['ButtonMenu__TopItemTitle']}
                        dangerouslySetInnerHTML={{__html: SanitizeHTML(topItemTitle)}}
                    />
                }
            </div>
        </button>
    );
};

export default ButtonMenu;
