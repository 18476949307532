/**
 * Footer
 */
import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import BaseContext from 'Layouts/BasePage/BaseContext';
import Follow from 'Components/Follow';
import EventSidebar from 'Components/EventSidebar';
import { useStyle } from 'Themes/theme';

import LogoMedborgarskolan from 'public/img/logo-medborgarskolan-white.svg';
import LogoPahlmans from 'public/img/logo-pahlmans.webp';
import LogoPahlmansGy from 'public/img/logo-pahlmans-gy.webp';
import LogoKulturama from 'public/img/logo-kulturama.svg';
import LogoTillskararakademin from 'public/img/logo-tillskararakademin.svg';
import LogoDansskolanentre from 'public/img/logo-dansskolan-entre-white.svg';

import sD from './Footer.Dansskolanentre.module.scss';
import sK from './Footer.Kulturama.module.scss';
import sM from './Footer.Medborgarskolan.module.scss';
import sP from './Footer.Pahlmans.module.scss';
import sT from './Footer.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Footer = ({menu, columns, follow, isMinimal, eventSidebar}) => {
    const {t, i18n} = useTranslation();

    const s = useStyle(styles);

    const { primary, secondary } = menu;

    if(_.isEmpty(primary.items) && _.isEmpty(secondary.items) && _.isEmpty(columns)) {
        return null;
    }

    const classes = classNames(
        s['Footer'],
        {[s['Footer--Minimal']]: isMinimal},
    );

    return (
        <footer className={classes}>
            {!_.isEmpty(follow) &&
                <Follow
                    {...follow}
                    isMinimal={isMinimal}
                />
            }
            <div className={s['Footer__Container']}>

                <div className={s['Footer__Wrap']}>

                    <Link href="/" className={classNames(s['Footer__Logo'], s['Footer__Logo--Top'])}>
                        <span className="sr-only">{t('footer.toStart')}</span>
                        <Logo isMinimal={isMinimal} />
                    </Link>

                    <div className={s['Footer__Content']}>

                        <nav className={s['Footer__Menu']}>
                            <div className={s['Footer__MenuColumn']}>
                                {i18n.exists('footer.primaryTitle') &&
                                    <h2 className={s['Footer__Title']}>{t('footer.primaryTitle')}</h2>
                                }

                                <ul className={s['Footer__MenuPrimary']}>
                                    {primary.items.map((item, index) =>
                                        <MenuItem
                                            key={index}
                                            {...item}
                                        />
                                    )}
                                </ul>
                            </div>

                            {secondary.items &&
                                <div className={s['Footer__MenuColumn']}>
                                    {i18n.exists('footer.secondaryTitle') &&
                                        <h2 className={s['Footer__Title']}>{t('footer.secondaryTitle')}</h2>
                                    }

                                    <ul className={s['Footer__MenuSecondary']}>
                                        {secondary.items.map((item, index) =>
                                            <MenuItem
                                                key={index}
                                                {...item}
                                            />
                                        )}
                                    </ul>
                                </div>
                            }
                        </nav>

                        <div className={s['Footer__Info']}>
                            {columns.map(({title, wysiwyg, items}, index) =>
                                <div
                                    className={s['Footer__Column']}
                                    key={index}
                                >
                                    {!_.isEmpty(title) &&
                                        <h2 className={s['Footer__Title']}>{title}</h2>
                                    }

                                    {!_.isEmpty(wysiwyg) &&
                                        <div
                                            className={s['Footer__Wysiwyg']}
                                            dangerouslySetInnerHTML={{__html: wysiwyg}}
                                        />
                                    }

                                    {!_.isEmpty(items) &&
                                        <ul className={s['Footer__Links']}>
                                            {items.map((item, index) =>
                                                <LinkItem
                                                    key={index}
                                                    {...item}
                                                />
                                            )}
                                        </ul>
                                    }
                                </div>
                            )}

                            <Link href="/" className={classNames(s['Footer__Logo'], s['Footer__Logo--Bottom'])}>
                                <span className="sr-only">{t('footer.toStart')}</span>
                                <Logo isMinimal={isMinimal} />
                            </Link>
                        </div>
                    </div>

                    {eventSidebar &&
                        <div className={s['Footer__EventSidebar']}>
                            <EventSidebar
                                {...eventSidebar}
                                modifier="Footer"
                            />
                        </div>
                    }
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    columns: PropTypes.array,
    follow: PropTypes.object,
    menu: PropTypes.object,
    isMinimal: PropTypes.bool,
};

Footer.defaultProps = {
    columns: [],
    follow: {},
    menu: {
        primary: {
            items: []
        },
        secondary: {
            items: []
        },
    },
    isMinimal: false,
};

const MenuItem = ({href, title}) => {
    const s = useStyle(styles);
    return (
        <li className={s['Footer__Item']}>
            <a className={s['Footer__Link']} href={href}>
                {title}
            </a>
        </li>
    );
};

MenuItem.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
};

MenuItem.defaultProps = {
    href: '',
    title: '',
};

const LinkItem = ({href, text, rel, target, title}) => {
    const s = useStyle(styles);
    return (
        <li className={s['Footer__Item']}>
            <a className={s['Footer__Link']} href={href} rel={rel} target={target} title={title}>
                {text}
            </a>
        </li>
    );
};

LinkItem.propTypes = {
    href: PropTypes.string,
    text: PropTypes.string,
    rel: PropTypes.string,
    target: PropTypes.string,
    title: PropTypes.string,
    isLocal: PropTypes.bool,
};

LinkItem.defaultProps = {
    href: '',
    text: '',
    rel: '',
    target: '',
    title: '',
    isLocal: false,
};

const Logo = () => {
    const {currentTheme} = useContext(BaseContext);
    const {t} = useTranslation();
    if(_.isEmpty(currentTheme)) {
        return null;
    }

    const logos = {
        Medborgarskolan: LogoMedborgarskolan,
        Pahlmans: LogoPahlmans,
        PahlmansGy: LogoPahlmansGy,
        Kulturama: LogoKulturama,
        Tillskararakademin: LogoTillskararakademin,
        Dansskolanentre: LogoDansskolanentre,
    };
    const logo = logos[currentTheme];
    const {src, width, height} = logo;

    return (
        <img
            src={src}
            alt={t('site.name')}
            width={width}
            height={height}
            loading="lazy"
        />
    );
};

Logo.propTypes = {
    isMinimal: PropTypes.bool,
};

Logo.defaultProps = {
    isMinimal: false,
};

export default Footer;
