/**
 * CookiePopup
 */
/* global gtag, dataLayer */
import React, {useState, useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import SanitizeHTML from 'sanitize-html-react';
import Crypto from 'crypto-js';
import _ from 'lodash';
import { httpPost } from 'utils/Http';
import { cookiePopupName, cookieVersion, getCookie, setCookie, removeCookie } from 'utils/Cookie';
import { AnimateUpDown, AnimateInOut } from 'Components/Animate';
import Accordion from './Accordion';
import { useStyle } from 'Themes/theme';

import sD from './CookiePopup.Dansskolanentre.module.scss';
import sK from './CookiePopup.Kulturama.module.scss';
import sM from './CookiePopup.Medborgarskolan.module.scss';
import sP from './CookiePopup.Pahlmans.module.scss';
import sT from './CookiePopup.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const CookiePopup = ({title, wysiwyg, items, cookieApiUrl}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [selected, setSelected] = useState([]);
    const [uniqueId, setUniqueId] = useState('');
    const [dataLayerCategories, setDataLayerCategories] = useState([]);

    const cookieName = cookiePopupName;
    const allCategories = [...items].map(({slug}) => slug);
    allCategories.shift();

    useEffect(() => {
        const removeCookies = (accepted = null) => {
            const removeAll = accepted === null || _.isEmpty(accepted);
            // Filter out the cookie category which is required, these cookies should never be removed
            const cookieCats = [...items].filter(
                ({slug}, index) => slug !== 'required' && index !== 0
            );
            // Create list of categories that have been denied and remove all their cookies
            const removeCats = removeAll ? cookieCats : cookieCats.filter(
                ({slug}) => !accepted.includes(slug)
            );
            _.each(removeCats, (cat) => _.each(cat.items, ({name}) => removeCookie(name)));
        };

        const generateUniqueID = () => {
            return Crypto.MD5('cookie ' + new Date().toString() + ' popup').toString();
        };

        const cookie = getCookie(cookieName);
        const currVersion = !_.isEmpty(cookie) ? _.get(JSON.parse(cookie), 'version', '') : '';
        const inValidate = currVersion !== cookieVersion;

        const key = !_.isEmpty(cookie) ? _.get(JSON.parse(cookie), 'key', '') : '';
        setUniqueId(!_.isEmpty(key) ? key : generateUniqueID());

        const status = !_.isEmpty(cookie) ? _.get(JSON.parse(cookie), 'status', '') : '';
        if(!inValidate && status === 'accept') {
            setOpen(false);
            const categories = _.get(JSON.parse(cookie), 'categories', []);
            const selCategories = categories === null ? [] : categories;
            setSelected(selCategories);
            updateGtagConsent(true, selCategories);
            removeCookies(categories);
        } else if(!inValidate && status === 'reject') {
            updateGtagConsent(false, []);
            removeCookies();
        } else { // User hasn't saved any settings or cookie version has been updated
            setOpen(true);
            removeCookies();
        }
    }, []);

    useEffect(() => {
        if(open) {
            document.querySelector('body').classList.add('no-scroll--xs');
        } else {
            document.querySelector('body').classList.remove('no-scroll--xs');
        }
    }, [open]);

    // Keep track of selected categories but only change data layer categories if new is added
    // Data layer events should only be used by GTM and only triggers event when a new category is accepted
    useEffect(() => {
        if(selected.length > 0) {
            const gtmCategories = {
                'analysis': 'performance',
                'ads': 'targeting',
                'preference': 'functionality',
            };

            // Make sure event for strict also is added
            ['strict', ...selected].map((cat) => {
                if(!dataLayerCategories.includes(cat)) {
                    const gtmCat = _.get(gtmCategories, cat, cat);
                    if(typeof dataLayer !== 'undefined') {
                        dataLayer.push({event: `CookieConsentCategory-${gtmCat}`});
                    }
                    setDataLayerCategories(prevTracked => [...prevTracked, cat]);
                }
            });
        }
    }, [selected, dataLayerCategories]);

    const updateGtagConsent = (isAccept, categories) => {
        // Make sure each gtag category is added to update
        const update = items.filter(
            ({gtagConsent}) => !_.isEmpty(gtagConsent)
        ).reduce((obj, {slug, gtagConsent}) => {
            const val = isAccept && categories.includes(slug) ? 'granted' : 'denied';
            // Create object with gtag key and value
            return {...obj, ..._.mapValues(_.mapKeys(gtagConsent), () => val)};
        }, {});
        if(!_.isEmpty(update)) {
            try {
                Object.keys(update).map((key) => {
                    gtag('consent', 'update', {
                        [key]: update[key],
                    });
                });
                if(update.ad_storage === 'denied') {
                    gtag('set', 'ads_data_redaction', true);
                }
            } catch(err) {
                console.error('gtag is missing');
            }
        }
    };

    // Set categories to null if not accepted
    const saveCookie = (status, categories) => {
        const data = {
            status: status,
            key: uniqueId,
            categories: categories,
            version: cookieVersion,
        };
        setCookie(cookieName, JSON.stringify(data));
    };

    const saveConsentToDb = (status, categories) => {
        const data = {
            key: uniqueId,
            status: status,
            categories: categories,
            title: title,
            text: wysiwyg,
            url: window.location.href,
            browser: window.navigator.userAgent,
        };
        httpPost(cookieApiUrl, data, {
            'X-CSRFToken': getCookie('csrftoken')
        }).then(() => {
            // console.log('saved consent', result);
        }).catch((err) => {
            console.error('something went wrong', err);
        });
    };

    // Set categories to null if not accepted
    const changeConsent = (categories = null) => {
        const isAccept = !_.isEmpty(categories);
        const status = isAccept ? 'accept' : 'reject';
        saveCookie(status, categories);
        updateGtagConsent(isAccept, categories);
        saveConsentToDb(status, categories);

        const event = new Event('cookie-popup-changed');
        document.dispatchEvent(event);
    };

    const submitSettings = (e) => {
        e.preventDefault();
        // Is either array of elements or one single
        const els = e.target.elements.category;
        const checkboxes = els && els.length === undefined ? [els] : [...els];
        if(checkboxes.length) {
            const checked = checkboxes.filter((el) => el.checked).map((el) => el.value);
            setSelected(checked);
            changeConsent(checked);
            setOpen(false);
        }
    };

    const sanitizedText = SanitizeHTML(wysiwyg, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a'],
        allowedAttributes: {a: ['href', 'target']},
        allowedSchemes: ['http', 'https', 'mailto', 'tel'],
    });

    const classes = classNames(
        s['CookiePopup'],
        {[s['CookiePopup--Open']]: open},
        {[s['CookiePopup--Expanded']]: expanded},
    );

    return (
        <aside className={classes} id="cookie-popup">
            <button
                type="button"
                className={s['CookiePopup__Button']}
                onClick={() => setOpen(true)}
            >
                {t('cookiePopup.button')}
            </button>

            <AnimateInOut
                isVisible={open}
            >
                <div className={s['CookiePopup__Popup']}>
                    <div className={s['CookiePopup__Container']}>
                        <div className={s['CookiePopup__Scroll']}>
                            <div className={s['CookiePopup__Content']}>
                                <h2 className={s['CookiePopup__Title']}>{title}</h2>

                                {!_.isEmpty(sanitizedText) &&
                                    <div
                                        className={s['CookiePopup__Text']}
                                        dangerouslySetInnerHTML={{__html: sanitizedText}}
                                    />
                                }

                                <div className={s['CookiePopup__Buttons']}>
                                    <button
                                        className={s['CookiePopup__Accept']}
                                        type="button"
                                        id="cookie-popup-accept-all"
                                        onClick={() => [
                                            changeConsent(allCategories),
                                            setSelected(allCategories),
                                            setOpen(false),
                                        ]}
                                    >
                                        <span>{t('cookiePopup.acceptAll')}</span>
                                    </button>

                                    <button
                                        className={s['CookiePopup__Deny']}
                                        type="button"
                                        id="cookie-popup-deny-all"
                                        onClick={() => [
                                            changeConsent(null),
                                            setSelected([]),
                                            setOpen(false),
                                        ]}
                                    >
                                        <span>{t('cookiePopup.denyAll')}</span>
                                    </button>
                                </div>

                                {!_.isEmpty(items) &&
                                    <Fragment>
                                        <button
                                            className={s['CookiePopup__Link']}
                                            type="button"
                                            id="cookie-popup-change-settings"
                                            onClick={() => setExpanded(!expanded)}
                                        >
                                            {t('cookiePopup.changeSettings')}
                                        </button>

                                        <AnimateUpDown
                                            isVisible={expanded}
                                        >
                                            <form
                                                className={s['CookiePopup__Settings']}
                                                onSubmit={(e) => submitSettings(e)}
                                                id="cookie-popup-settings-form"
                                            >
                                                {items.map((item, index) => (
                                                    <Accordion
                                                        key={index}
                                                        {...item}
                                                        isLocked={index === 0}
                                                        selected={selected}
                                                    />
                                                ))}

                                                <button className={s['CookiePopup__Submit']} type="submit">
                                                    <span>{t('cookiePopup.acceptSelection')}</span>
                                                </button>
                                            </form>
                                        </AnimateUpDown>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </AnimateInOut>
        </aside>
    );
};

CookiePopup.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    items: PropTypes.array.isRequired,
    cookieApiUrl: PropTypes.string.isRequired,
};

CookiePopup.defaultProps = {
    title: '',
    text: '',
    items: [],
    cookieApiUrl: '',
};

export default CookiePopup;
