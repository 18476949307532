import BasePage from './BasePage';
import React from 'react';
import {snakeCaseToCamelCase} from '../../utils';

export const basePageWrap = (Component) => (props) => {
    const camelProps = {...snakeCaseToCamelCase(props)};

    return (
        <BasePage {...camelProps}  _class={Component.name}>
            <Component {...camelProps} />
        </BasePage>
    );
};

export default BasePage;
