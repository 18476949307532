/**
 * Table
 */

import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import SanitizeHTML from 'sanitize-html-react';
import _ from 'lodash';
import { useStyle } from 'Themes/theme';

import sD from './Table.Dansskolanentre.module.scss';
import sK from './Table.Kulturama.module.scss';
import sM from './Table.Medborgarskolan.module.scss';
import sP from './Table.Pahlmans.module.scss';
import sT from './Table.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Table = ({items}) => {
    const {t} = useTranslation();

    const s = useStyle(styles);

    return (
        <table className={s['CookiePopupTable']}>
            <thead className={s['CookiePopupTable__Head']}>
                <tr className={s['CookiePopupTable__Row']}>
                    <th className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Head'])}>
                        {t('cookiePopup.table.cookie')}
                    </th>
                    <th className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Head'])}>
                        {t('cookiePopup.table.expire')}
                    </th>
                    <th className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Head'])}>
                        {t('cookiePopup.table.domain')}
                    </th>
                    <th className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Head'])}>
                        {t('cookiePopup.table.company')}
                    </th>
                    <th className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Head'])}>
                        {t('cookiePopup.table.purpose')}
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody className={s['CookiePopupTable__Body']}>
                {items.map((item, index) => (
                    <Row key={index} {...item} />
                ))}
            </tbody>
        </table>
    );
};

Table.propTypes = {
    items: PropTypes.array.isRequired,
};

Table.defaultProps = {
    items: [],
};

const Row = ({name, expire, domain, company, purpose, text}) => {
    const s = useStyle(styles);

    const {t} = useTranslation();

    const [open, setOpen] = useState(false);

    const sanitizeText = (str) => {
        return SanitizeHTML(str, {
            allowedTags: ['b', 'i', 'em', 'strong'],
        });
    };

    const classes = classNames(
        s['CookiePopupTable__Row'],
        {[s['CookiePopupTable__Row--Open']]: open},
    );

    return (
        <Fragment>
            <tr className={classes}>
                <td
                    className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Body'])}
                    dangerouslySetInnerHTML={{__html: sanitizeText(name)}}
                />
                <td
                    className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Body'])}
                    dangerouslySetInnerHTML={{__html: sanitizeText(expire)}}
                />
                <td
                    className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Body'])}
                    dangerouslySetInnerHTML={{__html: sanitizeText(domain)}}
                />
                <td
                    className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Body'])}
                    dangerouslySetInnerHTML={{__html: sanitizeText(company)}}
                />
                <td
                    className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Body'])}
                    dangerouslySetInnerHTML={{__html: sanitizeText(purpose)}}
                />
                <td className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Body'])}>
                    {!_.isEmpty(text) &&
                        <button
                            type="button"
                            className={s['CookiePopupTable__Toggle']}
                            onClick={() => setOpen(!open)}
                        >
                            <span className="sr-only">
                                {t('cookiePopup.toggleRow')}
                            </span>
                        </button>
                    }
                </td>
            </tr>

            {!_.isEmpty(text) &&
                <tr className={classes}>
                    <td colSpan="5" className={classNames(s['CookiePopupTable__Cell'], s['CookiePopupTable__Cell--Text'])}>
                        <div className={s['CookiePopupTable__Text']}>
                            <p dangerouslySetInnerHTML={{__html: sanitizeText(text)}} />
                        </div>
                    </td>
                    <td></td>
                </tr>
            }
        </Fragment>
    );
};

Row.propTypes = {
    name: PropTypes.string.isRequired,
    expire: PropTypes.string,
    domain: PropTypes.string,
    company: PropTypes.string,
    purpose: PropTypes.string,
    text: PropTypes.string,
};

Row.defaultProps = {
    name: '',
    expire: '',
    domain: '',
    company: '',
    purpose: '',
    text: '',
};

export default Table;
