/**
 * Menu
 */

import React, {useState, useRef, useCallback} from 'react';
import classNames from 'classnames';
import { isSet } from 'utils/is';
import { useStyle } from 'Themes/theme';

import sD from './Menu.Dansskolanentre.module.scss';
import sK from './Menu.Kulturama.module.scss';
import sM from './Menu.Medborgarskolan.module.scss';
import sP from './Menu.Pahlmans.module.scss';
import sT from './Menu.Tillskararakademin.module.scss';

const styles = {
    Dansskolanentre: sD,
    Kulturama: sK,
    Medborgarskolan: sM,
    Pahlmans: sP,
    Tillskararakademin: sT,
};

const Menu = ({
    activeIds = [],
    items = [],
    modifier = '',
    placement = '',
    isMinimal = false,
}) => {
    const clickHandler = useCallback((e, item) => {
        if (item.onClick) {
            e.preventDefault();
            let fn = item.onClick;
            if (typeof fn === 'function') {
                fn();
            } else {
                fn = window[item.onClick];
                if (typeof fn === 'function') {
                    fn();
                }
            }
        }
    }, []);

    const s = useStyle(styles);

    const classes = classNames(
        s['Menu'],
        { [s[`Menu--${modifier}`]]: modifier },
        { [s[`Menu--${placement}`]]: placement },
        { [s['Menu--IsMinimal']]: isMinimal },
    );

    return (
        <nav className={classes}>
            <ul className={s['Menu__List']}>
                {items.map((item, index) => (
                    <MenuItem
                        key={index}
                        active={isSet(item) && activeIds.includes(item.id)}
                        clickHandler={(e) => clickHandler(e, item)}
                        {...item}
                    />
                ))}
            </ul>
        </nav>
    );
};

const MenuItem = ({title, href, active, clickHandler, modifier, attr}) => {
    const [loading, setLoading] = useState(false);
    const linkRef = useRef();

    const s = useStyle(styles);

    const classes = classNames(
        s['Menu__Item'],
        {[s['Menu__Item--Current']]: active},
        {[s[`Menu__Item--${modifier}`]]: modifier},
        {[s['Menu__Item--Loading']]: loading},
    );

    return (
        <li className={classes}>
            <a
                className={s['Menu__Link']}
                href={href}
                onClick={(e) => clickHandler(e)}
                ref={linkRef}
                {...attr}
            >
                <span>{title}</span>
            </a>
        </li>
    );
};

export default Menu;
